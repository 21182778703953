import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    items: [
      // ホーム
      {
        text: 'menu.home',
        href: '#',
      },
      // サイトについて
      {
        text: 'menu.about',
        href: '#about',
      },
    ],
  },
  getters: {
    links: (state) => {
      // return state.items.concat(getters.categories)
      return state.items
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
  },
  actions: {

  },
})
