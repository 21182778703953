<template>
  <div id="home">
    <image-converter />

    <v-container><v-divider/></v-container>
    
    <about-us />

  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      ImageConverter: () => import('@/components/home/ImageConverter'),
      AboutUs: () => import('@/components/home/About'),
    },

    mounted() {
      this.$gtag.pageview({page_path: '/', page_title: "ホーム画面"})
    }
  }
</script>
